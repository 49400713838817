<style lang="less" scoped>
.extra-wrapper {
  line-height: 55px;
  padding-right: 24px;

  .extra-item {
    display: inline-block;
    margin-right: 24px;

    a {
      margin-left: 24px;
    }
  }
}
.ant-form-item {
  margin-top: 10px;
}

.antd-pro-pages-dashboard-analysis-twoColLayout {
  position: relative;
  display: flex;
  display: block;
  flex-flow: row wrap;
  padding-bottom: 50px;
  overflow: hidden;
}

.antd-pro-pages-dashboard-analysis-salesCard {
  height: calc(100% - 24px);
  /deep/ .ant-card-head {
    position: relative;
  }
}

.dashboard-analysis-iconGroup {
  i {
    margin-left: 16px;
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
    transition: color 0.32s;
    color: black;
  }
}
.analysis-salesTypeRadio {
  position: absolute;
  right: 54px;
  bottom: 12px;
}

.ant-select-selection__rendered {
  line-height: 35px !important;
}
</style>

<style lang="less" scoped>
.input-left {
  width: 100px !important;
}
.ant-input {
  height: 35px;
}
/deep/ .ant-form-item-with-help {
  margin-bottom: 0 !important;
  line-height: 25px;
}

/deep/ .ant-card-body {
  padding-bottom: 14px !important;
  padding-right: 10px !important;
}
/deep/ .ant-card {
  height: 100% !important;
}
.ant-card-head {
  background-color: #2c3e5023;
}
/deep/ .ant-upload-select-picture-card {
  min-width: 80px !important;
  min-height: 80px !important;
}
.recipient-multi-box {
  box-sizing: border-box;
  > div {
  }
}
.Send-box {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  justify-content: space-between;
  box-sizing: border-box;
  .ant-row-flex {
    margin-left: 0 !important;
    width: 49%;
    margin-right: 20px !important;
    // border: 1px solid #2c3e5024;
    box-sizing: border-box !important;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    /*考虑浏览器兼容性*/
    -moz-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    > :nth-child(1) {
      padding-left: 0 !important;
    }
    > :nth-child(2) {
      padding-right: 0 !important;
    }
  }
  > :nth-child(odd) {
    margin-right: 0 !important;
  }
  > :nth-child(even) {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
</style>
<template>
  <div class="page-header-index-wide" v-if="isRouterAlive">
    <div class="antd-pro-pages-dashboard-analysis-twoColLayout" :class="isDesktop() ? 'desktop' : ''">
      <div class="table-operator">
        <a-button type="primary" @click="validate" v-preventReClick>下单并接单</a-button>
      </div>

      <div class="table-operator" style="margin-top: 20px">
        <a-button type="primary" @click="addAddress()">新增发货地址</a-button>
        &nbsp;&nbsp;&nbsp;
        <a-radio-group v-model="disPick">
          <a-radio :key="index" v-for="(item, index) in disPickData" :value="index">{{ item }}</a-radio>
        </a-radio-group>
      </div>

      <a-row :gutter="24" type="flex" :style="{ marginTop: '24px' }" class="recipient-multi-box">
        <a-col :xl="8" :lg="24" :md="24" :sm="24" :xs="24">
          <a-card class="card" title="收方" :bordered="false">
            <recipient-form ref="recipient" :showSubmit="false" :debit="'2'" />
          </a-card>
        </a-col>

        <a-col :xl="16" :lg="24" :md="24" :sm="24" :xs="24">
          <a-card :bordered="false" title="订单信息" :style="{ height: '100%' }">
            <multi-order-form ref="order" :showSubmit="false" />
          </a-card>
        </a-col>
      </a-row>
      <div class="Send-box">
        <a-row v-for="idx in total" :key="idx" :gutter="24" type="flex" :style="{ marginTop: '24px' }">
          <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
            <a-card class="card" title="寄方" :bordered="false">
              <send-form :ref="'send_' + idx" :showSubmit="false" :SendTheIndex="'2'"> </send-form>
            </a-card>
          </a-col>
          <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
            <a-card :bordered="false" title="物品信息" :style="{ height: '100%' }">
              <multi-goods-form :ref="'goods_' + idx" :showSubmit="false" />
            </a-card>
          </a-col>
        </a-row>
      </div>
    </div>

    <a-modal v-model="orderConfirmVisible" title="下单成功">
      <p>下单成功，请提醒调度及时安排提货</p>
      <template slot="footer">
        <a-button key="forward1"  @click="orderFreshen">刷新页面</a-button>
        <a-button key="forward"  type="primary" @click="orderConfirm">确认</a-button>
      </template>
    </a-modal>

  </div>
</template>

<script>
import moment from 'moment'
import { mixinDevice } from '@/utils/mixin'
import SendForm from './OnlineDisperseOrder/SendForm'
import RecipientForm from './OnlineDisperseOrder/RecipientForm'
import MultiGoodsForm from './WholeCarOrder/MultiGoodsForm'
import MultiOrderForm from './WholeCarOrder/MultiOrderForm'
import { enumerationData  } from '@/api/common'

import {
  onlineDisperseOrder,
  saveMultiOrder,
  requestShipperAddressParam,
  requestReceiverAddressParam,
} from '@/api/order'
export default {
  name: 'WholeCarOrder',
  mixins: [mixinDevice],
  components: {
    SendForm,
    RecipientForm,
    MultiGoodsForm,
    MultiOrderForm,
    onlineDisperseOrder,
  },

  data() {
    return {
      isRouterAlive: true,
      disPick: '1',
      successMsg: '',
      total: 2,
      orderConfirmVisible:false,
      disPickData: { 1: '请按顺序提货', 2: '司机自主提货' },
    }
  },
  mounted() {
   this.loadData()
  },
  methods: {
    loadData(){
        this.$refs.recipient.recipient()  
        this.$refs.order.order()  
        this.total = 2; 
        for (let i = 1; i <= this.total; i++) {            
            let send = 'send_' + i
            let goods = 'goods_' + i
            console.log("refSend",this.$refs[send][0])
            this.$refs[send][0].send()
            this.$refs[goods][0].goods()
        }                                 
        this.loadEnumerationData()
    },
    loadEnumerationData(){
        var enumerationParam = {tableName:'sysCOrder'}
        enumerationData(enumerationParam)
         .then(res => {
            if(res.code == 0){

              this.$refs.order.orderLoad(res.result)

            }else{
              this.$notification.error({
                message: '订单加载失败',
                description: res.message
              })
            }
          })


        var enumerationParam = {tableName:'sysCOrderGoods'}
        enumerationData(enumerationParam)
         .then(res => {
            if(res.code == 0){

              this.$refs.order.goodsLoad(res.result)

            }else{
              this.$notification.error({
                message: '订单加载失败',
                description: res.message
              })
            }
          })

     },

    validate() {
      const {
        $refs: { recipient, order },
        $notification,
      } = this
      const recipientForm = new Promise((resolve, reject) => {
        recipient.form.validateFields((err, values) => {
          if (err) {
            reject(err)
            return
          }
          var valuesData = requestReceiverAddressParam(values)
          resolve(valuesData)
        })
      })
      const orderForm = new Promise((resolve, reject) => {
        order.form.validateFields((err, values) => {
          if (err) {
            reject(err)
            return
          }
          values.expectPickupDate = ''
          values.expectDispatchDate = moment(values.expectDispatchDate).format('YYYY-MM-DD HH:mm:ss')
          resolve(values)
        })
      })

      var paramForm = [recipientForm, orderForm]
      for (let i = 1; i <= this.total; i++) {
        let sendForm = 'sendForm_' + i
        let goodsForm = 'goodsForm_' + i
        let send = 'send_' + i
        let goods = 'goods_' + i

        sendForm = new Promise((resolve, reject) => {
          this.$refs[send][0].form.validateFields((err, values) => {
            if (err) {
              reject(err)
              return
            }
            var valuesData = requestShipperAddressParam(values)
            console.log('sendForm' + i, valuesData)
            resolve(valuesData)
          })
        })

        goodsForm = new Promise((resolve, reject) => {
          this.$refs[goods][0].form.validateFields((err, values) => {
            if (err) {
              reject(err)
              return
            }
            ;(values.expectDispatchDate = ''),
              (values.expectPickupDate = moment(values.expectPickupDate).format('YYYY-MM-DD HH:mm:ss'))
            console.log('goodsForm' + i, values)
            resolve(values)
          })
        })
        paramForm.push(sendForm)
        paramForm.push(goodsForm)
      }

      this.errors = []
      Promise.all(paramForm)
        .then((list_values) => {
          console.log(list_values)
          var receiverList = [list_values[0]]
          var key = 2
          var shipperList = []
          for (let i = 1; i <= this.total; i++) {
            var sendInfo = list_values[key]
            var goodsInfo = list_values[key + 1]
            sendInfo.goodsInfo = goodsInfo
            shipperList.push(sendInfo)
            key = key + 2
          }
          var pickupSeq = 2
          if (this.disPick == 2) {
            pickupSeq = 1
          }
          var orderParam = { pickupWay: 2, pickupSeq: pickupSeq, dispatchWay: 1, dispatchSeq: 1 }
          console.log('orderParam', orderParam)
          orderParam = Object.assign({}, list_values[1], orderParam)
          orderParam.shipperList = shipperList
          orderParam.receiverList = receiverList
          console.log('orderParam', orderParam)
          return saveMultiOrder(orderParam).then((res) => {
            console.log('res', res)
            const _this = this
            if (res.code == 0) {
                this.orderConfirmVisible = true               
            } else {
              this.$notification['error']({
                message: '下单失败',
                description: res.message,
              })
            }
            return res.result
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },

    addAddress() {
      this.total++
    },

    orderFreshen(){
        this.orderConfirmVisible = false
        this.loadData()        
    },
    orderConfirm(){
        this.orderConfirmVisible = false
    },

  },

  created() {},
}
</script>

