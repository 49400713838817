<style lang="less" scoped>
.form {
  width: 100%;
  // display: flex !important;
  // flex-wrap: wrap;
}
.ant-form-item {
  width: 100%;
  min-height: 60px;
  margin: 0 0 0 0 !important;
  flex-direction: column;
}
/deep/ .ant-form-item-control-wrapper {
  width: 75%;
}
/deep/ .ant-form-item-label {
  width: 75px !important;
  text-align: left !important;
}
.goods-input{
 /deep/ .ant-form-item-children{
    display: flex;
    .ant-input-number{
      border-radius: 5px 0 0 5px ;
    }
    .ant-btn{
      border-radius:0 5px 5px 0;
      border-left: none;
      width: 20% !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
<template>
  <a-form layout="inline" style="width: 100%"  :form="form" class="form">
    <a-form-item label="物品名称">
      <a-input v-decorator="['goodsName', { rules: [{ required: true, message: '物品名称' }] }]" style="width: 100%" :maxLength="10" placeholder="10字以内" />
    </a-form-item>
    <a-form-item label="总件数">
      <a-input-number  :min="1"
        :max="99999"
        :step="1"
        :precision="0" v-decorator="['goodsNum', { rules: [{ required: true, message: '总件数' }] }]" style="width: 100%" />
    </a-form-item>
    <a-form-item label="总重量" class="goods-input">
      <a-input-number   :min="0.1"
        :max="99999"
        :step="0.1"
        :precision="1"  v-decorator="['weight', { rules: [{ required: true, message: '重量' }] }]" style="width: 85%" /> <a-button disabled>KG</a-button>
    </a-form-item>
    <a-form-item label="总体积" class="goods-input">
      <a-input-number  :min="0.01"
        :max="99999"
        :step="0.01"
        :precision="2"  v-decorator="['volume', { rules: [{ required: true, message: '体积' }] }]" style="width: 85%" /> <a-button disabled>M³</a-button>
    </a-form-item>
    <a-form-item label="发货时间">
      <a-date-picker
        v-decorator="['expectPickupDate', { rules: [{ required: true, message: '发货时间' }] }]"
        show-time
        style="width: 100%"
      />
    </a-form-item>
  </a-form>
</template>    
<script>
function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}
export default {
  name: 'GoodsForm',
  components: {},
  props: {
   
  },
  data() {
    return {
      totalVolume: '',
      imageUrl: '',
      form: this.$form.createForm(this),
    }
  },
  mounted() {
    this.goods()
  },
  methods: {
    goods(){        
      const {
        form: { setFieldsValue },
      } = this
      this.form.resetFields()
    }
    
  },
}
</script>
